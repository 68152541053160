import React, { useEffect, useState, memo } from 'react'

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Input,
  Chip,
  Tooltip,
  FormHelperText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { fromJS } from 'immutable'
import clsx from 'clsx'
import { useToasts } from 'react-toast-notifications'
import {
  BASE_LANGUAGE_TOOLTIP_ANCHOR,
  SURVEY_ADMIN_MSG,
} from '../../../../utils/constants'

import {
  SwitchToggleSet,
  ModifiedSelect,
  DatePicker,
} from '../../../../components'
import useStyles from './styles'
import tooltipicongray from '../../assets/tooltip-icon.svg'
import tooltipblue from '../../assets/tooltip-icon_blue.png'
import tooltiporange from '../../assets/tooltip_Orange.svg'

import moment from 'moment'
import validate from '../../../../validations/formValidation'
import ProgressBarLoader from '../../../../components/ProgressBarLoader'
import PopupModal from '../../../../components/PopupModal'
import StorageInstance from '../../../../common/Storage'

function SurveyForm({
  languages,
  onFetch,
  analysts,
  onAnalystsFetch,
  countries,
  onCountriesFetch,
  regions,
  onRegionsFetch,
  populations,
  onPopulationsFetch,
  surveytypes,
  onSurveytypeFetch,
  resurvey,
  onResurveyFetch,
  surveyinfo,
  onSurveyinfoFetch,
  params,
  onSurveySave,
  savesurveyStatus,
  history,
  loader,
  statuscode,
  isButtonToBeDisabled,
  OhiVersionList,
  OhiVersionFetch,
  defaultohiVersionId,
}) {
  const classes = useStyles()
  const dateFormat = 'Do MMM, YYYY'

  const countryOptions = fromJS([
    {
      id: 1,
      name: 'One',
    },
    {
      id: 2,
      name: 'Multiple',
    },
  ])

  const surveyAdministrations = [
    {
      name: 'Back Coded',
    },
    {
      name: 'Generic',
    },
    {
      name: 'Paper',
    },
  ].map(({ name }, index) => ({
    id: index + 1,
    name,
  }))
  const [useForm, setStatform] = useState({
    isReSurvey: false,
    ohiId: '',
    statusId: 1,
    analyst: [],
    clientName: '',
    expertName: '',
    languages: [],
    expertEmail: '',
    surveyAdmin: [],
    startDate: '',
    endDate: '',
    surveyType: 0,
    isMultipleCountries: 0,
    population: '',
    subPopulation: 0,
    subPopulationSpecify: '',
    regionId: 0,
    subRegion1Id: 0,
    subRegion2Id: 0,
    isSample: false,
    sampleValue: 0,
    pId: '',
    id: 0,
    surveyName: '',
    countryId: 0,
    baseLanguage: 0,
    reSurveyId: 0,
    singleRegin: [],
    ohiVersionId: 3,
  })
  const [values, setState] = useState({
    isReSurvey: true,
    ohiId: [1],
    id: params,
    test: false,
  })
  const [subvalue, setSubState] = useState({
    subRegion1IdArr: [],
    subRegion2IdArr: [],
  })
  const [subPopulationArr, setSubPopulationArr] = useState([])
  const [btnLoading, setBtnLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const { addToast } = useToasts()
  const [nextpage, setNextpage] = useState(false)
  const [open, setOpen] = useState(false)
  const [clearRegion, setClearRegion] = useState(true)
  const [clearsubRegion2, setClearsubRegion2] = useState(true)
  const [clearmainCountry, setClearmainCountry] = useState(true)
  const [isSoftWarningPopupOpen, openSoftWarningPopup] = useState(false)
  const [defaultOhiVersion, setDefaultOhiVersion] = useState(0)
  const [ohiversionDefault, setohiversionDefault] = useState(3)

  useEffect(() => {
    !analysts.get('isFetched') && onAnalystsFetch()
    !countries.get('isFetched') && onCountriesFetch()
    !regions.get('isFetched') && onRegionsFetch()
    !populations.get('isFetched') && onPopulationsFetch()
    !surveytypes.get('isFetched') && onSurveytypeFetch()
    !OhiVersionList.get('isFetched') && OhiVersionFetch()
    onResurveyFetch(params)
  }, [])

  useEffect(() => {
    onSurveyinfoFetch(params)
  }, [])

  useEffect(() => {
    if (OhiVersionList.toJS().items.length) {
      const defaultVersionId = OhiVersionList.toJS().items.filter(
        item => item['isSeleted']
      )[0]['id']
      setStatform({
        ...useForm,
        ohiVersionId: defaultVersionId,
      })
      onFetch(defaultVersionId)
      setohiversionDefault(defaultVersionId)
      setDefaultOhiVersion(defaultVersionId)
    }
  }, [OhiVersionList])

  useEffect(() => {
    if (
      statuscode &&
      statuscode.get('errorstatus') &&
      statuscode.get('errorstatus') !== ''
    ) {
      if (statuscode.toJS().errorstatus.payload.status === 500) {
        setBtnLoading(false)
      }
    }
  }, [statuscode])

  useEffect(() => {
    if (nextpage && savesurveyStatus.get('isFetched')) {
      return () => {
        setNextpage(false)
      }
    }
  })

  useEffect(() => {
    if (savesurveyStatus.get('isFetched') && btnLoading) {
      setBtnLoading(false)

      if (savesurveyStatus.get('items').get('surveyUrl') == '') {
        StorageInstance.surveyInfo = true
        history.push(`../design-survey/${params}`)
      }
    }
  }, [savesurveyStatus])

  useEffect(() => {
    setStatform({
      ...surveyinfo.toJS().items,
      startDate:
        surveyinfo.toJS().items.startDate != ''
          ? moment(surveyinfo.toJS().items.startDate, 'DD/MM/YYYY').format(
              dateFormat
            )
          : '',
      endDate:
        surveyinfo.toJS().items.endDate != ''
          ? moment(surveyinfo.toJS().items.endDate, 'DD/MM/YYYY').format(
              dateFormat
            )
          : '',
    })
    if (surveyinfo.toJS()?.items?.ohiVersionId) {
      onFetch(surveyinfo.toJS()?.items?.ohiVersionId)
    }
  }, [surveyinfo])

  useEffect(() => {
    if (useForm.population !== 0 && useForm.population != undefined) {
      const allpopulations = populations.toJS().items
      const subpopulationObj = allpopulations.filter(
        item => item.id == useForm.population
      )
      if (subpopulationObj.length > 0) {
        setSubPopulationArr(subpopulationObj[0].children)
      }
    }
  }, [useForm.population])

  //region id1
  useEffect(() => {
    if (useForm.regionId !== 0 && useForm.regionId !== undefined) {
      const allregionId = regions.toJS().items
      const subRegion1IdObj = allregionId.filter(
        item => item.id == useForm.regionId
      )
      if (subRegion1IdObj.length > 0) {
        setSubState({
          ...subvalue,
          subRegion1IdArr: subRegion1IdObj[0].children,
        })
      }
    }
    ///console.log('regionchange')
  }, [useForm.regionId])
  // subregion id 2
  useEffect(() => {
    if (useForm.subRegion1Id !== 0 && useForm.subRegion1Id !== undefined) {
      const allsubRegion1Id = subvalue.subRegion1IdArr
      const subRegion2IdObj = allsubRegion1Id.filter(
        item => item.id == useForm.subRegion1Id
      )
      if (subRegion2IdObj.length > 0) {
        setSubState({
          ...subvalue,
          subRegion2IdArr: subRegion2IdObj[0].children,
        })
      }
    }
  }, [useForm.subRegion1Id, subvalue.subRegion1IdArr])

  if (surveyinfo && surveyinfo.get('isLoading')) {
    return (
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        className={classes.root}
      >
        <Box style={{ position: 'relative', minHeight: 250 }}>
          <PopupModal>
            <ProgressBarLoader />
          </PopupModal>
        </Box>
      </Box>
    )
  }
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      className={classes.root}
    >
      {isSoftWarningPopupOpen && (
        <PopupModal>
          <Box>
            <h4 className="modal-title" style={{ marginBottom: '4.2vh' }}>
              Leadership Only survey are not allowed. If you have explicit
              permission from your Expert to run a ‘Leadership Only’ survey,
              please proceed and make sure you mark this as a ‘Special Survey’
              when doing closing protocols.
            </h4>
            <Box display="flex" flex="1" justifyContent="center">
              <Box style={{ position: 'relative' }}>
                <button
                  className="button-round yes-button"
                  onClick={() => openSoftWarningPopup(false)}
                >
                  OKAY
                </button>
              </Box>
            </Box>
          </Box>
        </PopupModal>
      )}
      {renderFirstSet()}
      {surveyinfo.get('isFetched') ? renderSecondSet() : null}
      {surveyinfo.get('isFetched') ? renderFormBottom() : null}
    </Box>
  )

  function renderSurveySample() {
    //console.log('population', useForm.population)
    return (
      <Box display="flex">
        {useForm.population == 3 || useForm.population == 4 ? (
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            alignItems="flex-start"
            className={classes.inputSet}
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="sampleValue" className={classes.inputLabel}>
                What % was the sample?{' '}
                <Tooltip
                  className={classes.csttooltip}
                  interactive={true}
                  placement="right"
                  title={
                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                      <em>
                        Sampling is not recommended, but if you are using a
                        sampling approach please specify what percent of the
                        group (applicable to the whole company or part) will be
                        used as the sample.
                      </em>
                    </p>
                  }
                >
                  <img
                    src={tooltipblue}
                    alt="tooltip"
                    className={classes.tooltipicon}
                  />
                </Tooltip>
              </InputLabel>
              <Input
                type="number"
                id="sampleValue"
                className={classes.input}
                value={useForm.sampleValue || ''}
                onChange={handleInputChange('sampleValue')}
                fullWidth
              />
              {errors.sampleValue && (
                <FormHelperText
                  id="my-helper-text-sampleValue"
                  className={classes.myhelptext}
                >
                  {errors.sampleValue}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        ) : null}
      </Box>
    )
  }

  function renderSurveyPopulation() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <Box display="flex" className={classes.fullWidth}>
          <FormControl fullWidth>
            <ModifiedSelect
              options={populations.toJS().items}
              label={
                <Box>
                  Survey Population
                  <Tooltip
                    className={classes.csttooltip}
                    interactive={true}
                    placement="right"
                    title={
                      <p style={{ fontSize: 12, marginBottom: 0 }}>
                        <em>
                          If you surveyed only part of the company, please
                          specify which part below - this is not the same as
                          sampling
                        </em>
                      </p>
                    }
                  >
                    <img
                      src={tooltipblue}
                      alt="tooltip"
                      className={classes.tooltipicon}
                    />
                  </Tooltip>
                </Box>
              }
              inputId="surveyPopulation"
              placeholder="Select Population"
              optionValue="id"
              optionLabel="name"
              name="population"
              nestedName="selecton"
              selectevalue={useForm.population || ''}
              handleChangefn={handleSelectChange}
            />
            {errors.population && (
              <FormHelperText
                id="my-helper-text-population"
                className={classes.myhelptext}
              >
                {errors.population}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>
    )
  }

  function renderSelectOne() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <Box display="flex" paddingLeft="30px" className={classes.fullWidth}>
          <FormControl fullWidth>
            <ModifiedSelect
              options={subPopulationArr}
              label="Select One"
              inputId="subPopulation"
              placeholder="Select One"
              optionValue="id"
              optionLabel="name"
              name="subPopulation"
              selectevalue={useForm.subPopulation || ''}
              handleChangefn={handleSelectChange}
            />
            {errors.subPopulation && (
              <FormHelperText
                id="my-helper-text-subPopulation"
                className={classes.myhelptext}
              >
                {errors.subPopulation}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>
    )
  }

  function renderSpecify() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        paddingLeft="60px"
        className={classes.inputSet}
      >
        <FormControl fullWidth>
          <InputLabel
            htmlFor="subPopulationSpecify"
            className={classes.inputLabel}
          >
            Specify
          </InputLabel>
          <Input
            id="subPopulationSpecify"
            className={classes.input}
            value={useForm.subPopulationSpecify || ''}
            onChange={handleInputChange('subPopulationSpecify')}
            fullWidth
          />
        </FormControl>
      </Box>
    )
  }

  function renderMainCountry() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        {/* <Box className={classes.inputLabel}></Box> */}
        <Box display="flex" className={classes.fullWidth}>
          <FormControl fullWidth style={{ minHeight: 52 }}>
            {clearmainCountry ? (
              <ModifiedSelect
                options={
                  useForm.isMultipleCountries === 2
                    ? countries.toJS().items
                    : countries.toJS().items.filter(x => x.key !== 197)
                }
                label={
                  <Box>
                    Main Country of Survey
                    <Tooltip
                      className={classes.csttooltip}
                      interactive={true}
                      placement="left"
                      title={
                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                          <em>
                            If you have selected multiple, the main country is
                            where {'>'}70% of respondents will be surveyed, if
                            that is not applicable, please select Multiple
                          </em>
                        </p>
                      }
                    >
                      <img
                        src={tooltipblue}
                        alt="tooltip"
                        className={classes.tooltipicon}
                      />
                    </Tooltip>
                  </Box>
                }
                inputId="main-country-select"
                placeholder="Select main country..."
                optionValue="key"
                optionLabel="value"
                name="countryId"
                selectevalue={useForm.countryId || ''}
                handleChangefn={handleSelectChange}
              />
            ) : null}
            {errors.countryId && (
              <FormHelperText
                id="my-helper-text-countryId"
                className={classes.myhelptext}
              >
                {errors.countryId}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>
    )
  }

  function renderOneMultipleCountrySwitch() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <Box className={classes.inputLabel}>
          Will this survey be completed in one or multiple countries?
        </Box>
        <Box>
          {countryOptions.map(item => {
            const isActive = useForm.isMultipleCountries === item.get('id')
            return (
              <Chip
                key={item.get('id')}
                className={clsx({
                  [classes.chip]: true,
                  [classes.activeChip]: isActive,
                })}
                variant={isActive ? 'default' : 'outlined'}
                color={isActive ? 'primary' : 'default'}
                label={item.get('name')}
                onClick={handleChipClick('isMultipleCountries', item.get('id'))}
              />
            )
          })}
          {errors.isMultipleCountries && (
            <FormHelperText
              id="my-helper-text-isMultipleCountries"
              className={classes.myhelptext}
            >
              {errors.isMultipleCountries}
            </FormHelperText>
          )}
        </Box>
      </Box>
    )
  }

  function renderRegionOfSurvey() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
        style={{ height: 52 }}
      >
        {/* <Box className={classes.inputLabel}></Box> */}

        <Box display="flex" className={classes.fullWidth}>
          {useForm.countryId !== 197 ? (
            <FormControl fullWidth>
              <InputLabel htmlFor="regionmain" className={classes.inputLabel}>
                Region of Survey
              </InputLabel>
              {useForm.countryId &&
              countries.toJS().items &&
              countries.toJS().items.length > 0 ? (
                <Input
                  className={classes.input}
                  readOnly={true}
                  value={
                    countries
                      .toJS()
                      .items.filter(x => x.key === useForm.countryId)[0]
                      .RegionDetails.value || '-'
                  }
                  fullWidth
                />
              ) : null}
              {useForm.countryId === 0 ? (
                <Input
                  className={classes.input}
                  readOnly={true}
                  value={'-'}
                  fullWidth
                />
              ) : null}
            </FormControl>
          ) : (
            <FormControl fullWidth>
              {clearRegion ? (
                <ModifiedSelect
                  options={regions.toJS().items}
                  label="Region of Survey"
                  inputId="region-survey-select"
                  placeholder="Select region of survey..."
                  optionValue="id"
                  optionLabel="name"
                  name="regionId"
                  selectevalue={useForm.regionId || ''}
                  handleChangefn={handleSelectChange}
                />
              ) : null}

              {errors.regionId && (
                <FormHelperText
                  id="my-helper-text-regionId"
                  className={classes.myhelptext}
                >
                  {errors.regionId}
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Box>
      </Box>
    )
  }
  function renderSubRegion1() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        paddingLeft="30px"
        className={classes.inputSet}
        style={{ height: 52 }}
      >
        {/* <Box className={classes.inputLabel}></Box> */}
        <Box display="flex" className={classes.fullWidth}>
          {useForm.countryId !== 197 ? (
            <FormControl fullWidth>
              <InputLabel htmlFor="regionmain" className={classes.inputLabel}>
                Sub region 1
              </InputLabel>

              {useForm.countryId &&
              countries.toJS().items &&
              countries.toJS().items.length > 0 ? (
                <Input
                  className={classes.input}
                  readOnly={true}
                  value={
                    countries
                      .toJS()
                      .items.filter(x => x.key === useForm.countryId)[0]
                      .SubRegion1Details.value || '-'
                  }
                  fullWidth
                />
              ) : null}
              {useForm.countryId === 0 ? (
                <Input
                  className={classes.input}
                  readOnly={true}
                  value={'-'}
                  fullWidth
                />
              ) : null}
            </FormControl>
          ) : (
            <FormControl fullWidth>
              {clearRegion ? (
                <ModifiedSelect
                  options={subvalue.subRegion1IdArr}
                  label="Sub region 1"
                  inputId="sub-region-1-select"
                  placeholder="Select sub region 1..."
                  name="subRegion1Id"
                  optionValue="id"
                  optionLabel="name"
                  selectevalue={useForm.subRegion1Id || ''}
                  handleChangefn={handleSelectChange}
                />
              ) : null}
              {errors.subRegion1Id && (
                <FormHelperText
                  id="my-helper-text-subRegion1Id"
                  className={classes.myhelptext}
                >
                  {errors.subRegion1Id}
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Box>
      </Box>
    )
  }
  function renderSubRegion2() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        paddingLeft="30px"
        className={classes.inputSet}
        style={{ height: 52 }}
      >
        {/* <Box className={classes.inputLabel}></Box> */}
        <Box display="flex" className={classes.fullWidth}>
          {useForm.countryId !== 197 ? (
            <FormControl fullWidth>
              <InputLabel htmlFor="regionmain" className={classes.inputLabel}>
                Sub region 2
              </InputLabel>
              {useForm.countryId &&
              countries.toJS().items &&
              countries.toJS().items.length > 0 ? (
                <Input
                  className={classes.input}
                  readOnly={true}
                  value={
                    countries
                      .toJS()
                      .items.filter(x => x.key === useForm.countryId)[0]
                      .SubRegion2Details.value || '-'
                  }
                  fullWidth
                />
              ) : null}
              {useForm.countryId === 0 ? (
                <Input
                  className={classes.input}
                  readOnly={true}
                  value={'-'}
                  fullWidth
                />
              ) : null}
            </FormControl>
          ) : (
            <FormControl fullWidth>
              {clearsubRegion2 ? (
                <ModifiedSelect
                  options={subvalue.subRegion2IdArr}
                  label="Sub region 2"
                  inputId="sub-region-2-select"
                  placeholder="Select sub region 2..."
                  optionValue="id"
                  optionLabel="name"
                  name="subRegion2Id"
                  selectevalue={useForm.subRegion2Id || ''}
                  handleChangefn={handleSelectChange}
                />
              ) : null}
              {errors.subRegion2Id && (
                <FormHelperText
                  id="my-helper-text-subRegion2Id"
                  className={classes.myhelptext}
                >
                  {errors.subRegion2Id}
                </FormHelperText>
              )}
            </FormControl>
          )}
        </Box>
      </Box>
    )
  }

  function renderSurveyType() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <Box className={classes.inputLabel}>
          Survey Type
          <Tooltip
            className={classes.csttooltip}
            interactive={true}
            placement="right"
            title={
              <p style={{ fontSize: 12, marginBottom: 0 }}>
                <em>
                  It is considered a baseline survey if this is the first time
                  surveying this group. E.g. If a different part of the client
                  was surveyed previously [e.g., another BU/division, region],
                  this would be considered baseline for this part of the company
                </em>
              </p>
            }
          >
            <img
              src={tooltipblue}
              alt="tooltip"
              className={classes.tooltipicon}
            />
          </Tooltip>
        </Box>

        <Box>
          {surveytypes.get('items').map(item => {
            const isActive = useForm.surveyType === item.get('key')
            return (
              <Chip
                key={item.get('key')}
                className={clsx({
                  [classes.chip]: true,
                  [classes.activeChip]: isActive,
                })}
                variant={isActive ? 'default' : 'outlined'}
                color={isActive ? 'primary' : 'default'}
                label={item.get('value')}
                onClick={handleChipClick('surveyType', item.get('key'))}
              />
            )
          })}

          {errors.surveyType && (
            <FormHelperText
              id="my-helper-text-surveyType"
              className={classes.myhelptext}
            >
              {errors.surveyType}
            </FormHelperText>
          )}
        </Box>
      </Box>
    )
  }

  function renderResurveyOHIIdSet() {
    return (
      <Box display="flex">
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          alignItems="flex-start"
          className={classes.inputSet}
        >
          <Box className={classes.inputLabel}>
            Is it a Resurvey?
            <Tooltip
              className={classes.csttooltip}
              interactive={true}
              placement="right"
              title={
                <p style={{ marginBottom: 0, fontSize: 12 }}>
                  <em>
                    It is considered a resurvey only if the same group is being
                    surveyed as the former year survey. E.g. If the whole
                    company was surveyed previously, it would only be a resurvey
                    if the whole company was resurveyed, even if a sampling
                    approach was used. If only part of the company was surveyed
                    previously, it would only be a resurvey if the same part of
                    the company was resurveyed [e.g., a certain BU, division,
                    region, etc.]
                  </em>
                </p>
              }
            >
              <img
                src={tooltipblue}
                alt="tooltip"
                className={classes.tooltipicon}
              />
            </Tooltip>
          </Box>
          <Box>
            <SwitchToggleSet
              isChecked={useForm.isReSurvey || false}
              onChange={handleCheckboxChange}
              name="isReSurvey"
              text={{
                txtOn: 'Yes',
                txtOff: 'No',
                ariaLabel: 'Is resurvey checkbox',
              }}
            />
          </Box>
        </Box>
        {useForm.isReSurvey ? (
          <Box
            display="flex"
            flex="2"
            flexDirection="column"
            alignItems="flex-start"
            className={classes.inputSet}
          >
            {/* <Box className={classes.inputLabel}>
                    OHI Id for previous survey
                  </Box> */}
            <Box display="flex" className={classes.fullWidth}>
              <FormControl fullWidth>
                <ModifiedSelect
                  options={resurvey.toJS().items}
                  label="OHI Id for previous survey"
                  inputId="ohi-id-select"
                  placeholder="Select OHI id..."
                  optionValue="id"
                  optionLabel="ohiId"
                  optionSubLabel="surveyName"
                  name="reSurveyId"
                  selectevalue={useForm.reSurveyId || ''}
                  handleChangefn={handleSelectChange}
                />
                {errors.ohiId && (
                  <FormHelperText
                    id="my-helper-text-ohiId"
                    className={classes.myhelptext}
                  >
                    {errors.ohiId}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>
        ) : null}
      </Box>
    )
  }

  function renderohiversion() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <FormControl
          fullWidth
          style={{
            cursor: 'not-allowed',
          }}
        >
          <ModifiedSelect
            options={OhiVersionList.toJS().items}
            label={<div>OHI Version</div>}
            disabled={true}
            inputId="ohiVersion-select"
            placeholder="Select OHI Version..."
            optionValue="id"
            optionLabel="Name"
            name="ohiVersionId"
            selectevalue={useForm?.ohiVersionId || defaultOhiVersion}
            handleChangefn={handleSelectChange}
          />
        </FormControl>
      </Box>
    )
  }

  function renderClientName() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <FormControl fullWidth>
          <InputLabel htmlFor="clientName" className={classes.inputLabel}>
            Client Name for Survey
            <Tooltip
              className={classes.csttooltip}
              interactive={true}
              placement="right"
              title={
                <p style={{ marginBottom: 0, fontSize: 12 }}>
                  <em>
                    What Client wants to be called in the survey. <br></br>
                    <br></br>
                    The Client Name can only be modified once. If a change is
                    required after that, please reach out to OHI Helpdesk.
                  </em>
                </p>
              }
            >
              <img
                src={tooltipblue}
                alt="tooltip"
                className={classes.tooltipicon}
              />
            </Tooltip>
            {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
          </InputLabel>

          <Input
            id="clientName"
            className={classes.input}
            value={useForm.clientName || ''}
            onChange={handleInputChange('clientName')}
            readOnly={useForm.pId === '' ? false : true}
            fullWidth
          />
          {errors.clientName && (
            <FormHelperText
              id="my-helper-text-clientName"
              className={classes.myhelptext}
            >
              {errors.clientName}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    )
  }

  function renderLanguages() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        {/* <Box className={classes.inputLabel}></Box> */}
        {/* <Box>English, French, Spanish</Box> */}
        <Box display="flex" className={classes.fullWidth}>
          <FormControl fullWidth className={classes.languageForm}>
            <ModifiedSelect
              options={languages.toJS().items}
              label={
                <div className={classes.langualeLabel}>
                  Languages
                  <Tooltip
                    className={classes.csttooltip}
                    interactive={true}
                    placement="right"
                    title={
                      <p style={{ marginBottom: 0, fontSize: 12 }}>
                        <em>
                          Want to know what’s new with our standard languages
                          and process?
                          <a
                            target="_blank"
                            className={classes.tooltilink}
                            href={BASE_LANGUAGE_TOOLTIP_ANCHOR}
                          >
                            {' '}
                            CLICK HERE
                          </a>{' '}
                          to find out!
                        </em>
                      </p>
                    }
                  >
                    <img
                      src={tooltipblue}
                      alt="tooltip"
                      className={classes.tooltipicon}
                    />
                  </Tooltip>
                </div>
              }
              lablerighttxt="First language selected will be the base language"
              inputId="language-select"
              placeholder="Select language..."
              optionValue="key"
              optionLabel="value"
              name="languages"
              baseLanguage={useForm.baseLanguage || ''}
              selectevalue={useForm.languages || ''}
              handleChangefn={handleSelectChange}
              isMulti
            />
            {errors.languages && (
              <FormHelperText
                id="my-helper-text-languages"
                className={classes.myhelptext}
              >
                {errors.languages}
              </FormHelperText>
            )}
            <FormHelperText
              id="my-helper-text-languages-tp"
              className={classes.myhelptextLanguage}
            >
              <img
                src={tooltiporange}
                alt="tooltip"
                className={classes.tooltipicongray}
              />
              First language selected will be the base language
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
    )
  }

  function renderSurveyAdministration() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        {/* <Box className={classes.inputLabel}></Box> */}
        <Box display="flex" className={classes.fullWidth}>
          <FormControl fullWidth>
            <ModifiedSelect
              options={surveyAdministrations}
              label={
                <Box>
                  Survey Administration
                  <Tooltip
                    className={classes.csttooltip}
                    interactive={true}
                    placement="right"
                    title={
                      <div style={{ fontSize: 12, fontStyle: 'italic' }}>
                        <p>
                          <strong>Backcoded</strong> - Unique link for
                          respondents, All demo selections are uploaded up front
                          with the respondents
                        </p>
                        <p>
                          <strong>Generic</strong> - Open link for respondents,
                          demos will be self-selected
                        </p>
                        <p>
                          <strong>Paper</strong> - Administer the survey via
                          paper, must contact vendor for this option
                        </p>
                      </div>
                    }
                  >
                    <img
                      src={tooltipblue}
                      alt="tooltip"
                      className={classes.tooltipicon}
                    />
                  </Tooltip>
                </Box>
              }
              inputId="survey-administration-select"
              placeholder="Select survey administration..."
              name="surveyAdmin"
              selectevalue={useForm.surveyAdmin || ''}
              handleChangefn={handleSelectChange}
              isMulti
            />
            {useForm.surveyAdmin &&
              useForm.surveyAdmin.length > 1 &&
              useForm.surveyAdmin.includes(1) &&
              useForm.surveyAdmin.includes(2) && (
                <Box className={classes.noteLabel}>{SURVEY_ADMIN_MSG}</Box>
              )}
            {errors.surveyAdmin && (
              <FormHelperText
                id="my-helper-text-surveyAdmin"
                className={classes.myhelptext}
              >
                {errors.surveyAdmin}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>
    )
  }

  function renderAnalysts() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet2}
      >
        {/* <Box className={classes.inputLabel}>Analyst</Box> */}
        <Box display="flex" className={classes.fullWidth}>
          <FormControl fullWidth>
            <ModifiedSelect
              options={analysts.toJS().items}
              label="Owner"
              inputId="analyst-select"
              placeholder="Select Owner..."
              optionValue="key"
              optionLabel="value"
              name="analyst"
              selectevalue={useForm.analyst || ''}
              handleChangefn={handleSelectChange}
              isMulti
            />
            {errors.analyst && (
              <FormHelperText
                id="my-helper-text-analyst"
                className={classes.myhelptext}
              >
                {errors.analyst}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      </Box>
    )
  }

  function renderExpertName() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <FormControl fullWidth>
          <InputLabel
            htmlFor="expertName"
            className={classes.inputLabel}
            data-shrink={true}
            style={{
              transform: 'translate(0, 1.5px) scale(0.75)',
              transformOrigin: 'top left',
            }}
          >
            Expert’s Name & Email ID
            <Tooltip
              className={classes.csttooltip}
              interactive={true}
              placement="left"
              title={
                <p style={{ fontSize: 12, marginBottom: 0 }}>
                  <em>
                    This person will be the escalation point and final
                    accountable person on legal and billing calls
                  </em>
                </p>
              }
            >
              <img
                src={tooltipblue}
                alt="tooltip"
                className={classes.tooltipiconSl}
                style={{ width: '15px !important' }}
              />
            </Tooltip>
          </InputLabel>
          {/* <Input
            id="expertName"
            disabled={true}
            className={classes.input}
            value={useForm.expertName || ''}
            onChange={handleInputChange('expertName')}
            fullWidth
          /> */}
          <Box className={classes.expertCtrl}>
            <Box className={classes.input} component="span">
              {useForm.expertName || '-'}
            </Box>
            {useForm.expertName !== '' ? (
              <Box className={classes.surveyExpertName}>
                {useForm.expertEmail}
              </Box>
            ) : null}
          </Box>

          {errors.expertName && (
            <FormHelperText id="my-helper-text" className={classes.myhelptext}>
              {errors.expertName}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    )
  }

  function renderExpertEmail() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <FormControl fullWidth>
          <InputLabel htmlFor="expertEmail" className={classes.inputLabel}>
            Expert’s Email ID
          </InputLabel>
          <Input
            type="email"
            id="expertEmail"
            className={classes.input}
            value={useForm.expertEmail || ''}
            onChange={handleInputChange('expertEmail')}
            fullWidth
          />
          {errors.expertEmail && (
            <FormHelperText
              id="my-helper-text-expertEmail"
              className={classes.myhelptext}
            >
              {errors.expertEmail}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    )
  }
  function renderLaunchEndDate() {
    const td = new Date()
    const dd = td.getDate()
    const md = td.getMonth() + 1
    const yy = td.getFullYear()
    const todaydate = md + '/' + dd + '/' + yy

    return (
      <Box
        display="flex"
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box className={classes.dateTooltip}></Box>
        <Box
          display="flex"
          flex="1"
          paddingRight="30px"
          flexDirection="column"
          alignItems="flex-start"
          className={classes.inputSet}
          style={{ marginBottom: 46 }}
        >
          <DatePicker
            id="launchDate"
            onApply={handleLaunchDateApply}
            label={
              <Box>
                Tentative Launch Date
                <Tooltip
                  className={classes.csttooltip}
                  interactive={true}
                  placement="left"
                  title={
                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                      <em>Tentative dates for OHI-Helpdesk team</em>
                    </p>
                  }
                >
                  <img
                    src={tooltipblue}
                    alt="tooltip"
                    className={classes.tooltipicon}
                  />
                </Tooltip>
              </Box>
            }
            mindateValue={todaydate}
            placeholder="Select Date"
            values={{
              startDate: useForm.startDate || '',
            }}
          />
          {errors.startDate && (
            <FormHelperText
              id="my-helper-text-startDate"
              className={classes.myhelptext}
            >
              {errors.startDate}
            </FormHelperText>
          )}
          <Box className={classes.noteLabel}>
            Note: In order to 'Submit to Helpdesk' after completing your edits,
            the launch date must be at least 3 working days from the day you
            submit to give time for the testing and QA process.
          </Box>
        </Box>

        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          paddingLeft="0"
          alignItems="flex-start"
          className={classes.inputSet}
        >
          <DatePicker
            id="endDate"
            onApply={handleEndDateApply}
            label="Tentative Close Date"
            mindateValue={todaydate}
            values={{
              startDate: useForm.endDate || '',
            }}
          />

          {errors.endDate && (
            <FormHelperText
              id="my-helper-text-startDate"
              className={classes.myhelptext}
            >
              {errors.endDate}
            </FormHelperText>
          )}
        </Box>
      </Box>
    )
  }
  function renderFirstSet() {
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" component="h3" className={classes.sectionHeading}>
          Survey Information
        </Box>
        <Box display="flex">
          {renderLeft()}
          {renderRight()}
        </Box>
      </Box>
    )

    function renderLeft() {
      return (
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          className={classes.leftSection}
        >
          {renderResurveyOHIIdSet()}
          {renderohiversion()}
          {renderClientName()}
          {renderLanguages()}
          {renderSurveyAdministration()}
        </Box>
      )
    }

    function renderRight() {
      return (
        <Box display="flex" flex="1" flexDirection="column">
          {renderAnalysts()}
          {renderExpertName()}
          {renderLaunchEndDate()}
        </Box>
      )
    }
  }

  function renderSecondSet() {
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" component="h3" className={classes.sectionHeading}>
          Identify Survey Type and Population
        </Box>
        <Box display="flex">
          {renderLeft()}
          {renderRight()}
        </Box>
      </Box>
    )

    function renderLeft() {
      return (
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          className={classes.leftSection}
        >
          {renderSurveyType()}
          {renderSurveyPopulation()}
          {subPopulationArr && subPopulationArr.length > 0
            ? renderSelectOne()
            : null}
          {useForm.subPopulation !== 0 &&
          useForm.subPopulation !== 1 &&
          useForm.population !== 1
            ? renderSpecify()
            : null}
          {renderSurveySample()}
        </Box>
      )
    }

    function renderRight() {
      return (
        <Box display="flex" flex="1" flexDirection="column">
          {renderOneMultipleCountrySwitch()}
          {renderMainCountry()}
          {renderRegionOfSurvey()}
          {renderSubRegion1()}
          {renderSubRegion2()}
        </Box>
      )
    }
  }

  function renderFormBottom() {
    return (
      <Box display="flex" className={classes.formBottom}>
        <Box
          style={{
            textAlign: 'left',
            color: '#999999',
            marginTop: -30,
            marginLeft: -40,
          }}
        >
          *Kindly adjust webpage Zoom if buttons are overlapping. Currently
          webpage is not optimized for smaller screens.
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonRound}
            onClick={goToClientinfo()}
          >
            Previous
          </Button>
          <Box style={{ position: 'relative' }}>
            <Button
              color="primary"
              variant="contained"
              disabled={btnLoading}
              className={
                isButtonToBeDisabled
                  ? classes.buttonRoundDisabled
                  : classes.buttonRound
              }
              onClick={() => handleOpen()}
            >
              Save & Continue
            </Button>
            {btnLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">
              {"Use Google's location service?"}
            </DialogTitle> */}
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                component="div"
                style={{ paddingTop: 25 }}
              >
                <Box component="p">
                  Please note, once you save and proceed to the design screen
                  you WILL NOT be able to change the base language
                  (adding/removing other languages is ok) or change the "Client
                  Name" in the survey.
                </Box>
                <Box component="p" style={{ fontWeight: 700 }}>
                  Are you sure you would like to proceed?
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: 25, paddingTop: 0 }}>
              <Button
                onClick={() => handleClose('no')}
                color="primary"
                variant="outlined"
                className={classes.buttonRound}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleClose('yes')}
                color="primary"
                variant="contained"
                autoFocus
                className={classes.buttonRound}
              >
                Yes, Proceed
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    )
  }
  function goToClientinfo() {
    return function() {
      setState({
        ...values,
        test: false,
      })
      setStatform({})
      history.push(`../client-info/${params}`)
    }
  }
  function handleClose(action) {
    const sendvalue = JSON.parse(JSON.stringify(useForm))
    if (!sendvalue.ohiVersionId) {
      sendvalue.ohiVersionId = ohiversionDefault
    }
    if (sendvalue.startDate !== '') {
      sendvalue.startDate = moment(sendvalue.startDate, 'Do MMM, YYYY').format(
        'DD/MM/YYYY'
      )
    }
    if (sendvalue.endDate !== '') {
      sendvalue.endDate = moment(sendvalue.endDate, 'Do MMM, YYYY').format(
        'DD/MM/YYYY'
      )
    }

    if (action === 'no') {
      setOpen(false)
    }
    if (action === 'yes') {
      setOpen(false)
      saveNContinue(sendvalue)
    }
  }
  function handleOpen() {
    const sendvalue = JSON.parse(JSON.stringify(useForm))
    // sendvalue.ohiVersionId = ohiversionDefault;
    if (sendvalue.startDate !== '') {
      sendvalue.startDate = moment(sendvalue.startDate, 'Do MMM, YYYY').format(
        'DD/MM/YYYY'
      )
    }
    if (sendvalue.endDate !== '') {
      sendvalue.endDate = moment(sendvalue.endDate, 'Do MMM, YYYY').format(
        'DD/MM/YYYY'
      )
    }
    if (!sendvalue.ohiVersionId) {
      sendvalue.ohiVersionId = ohiversionDefault
    }
    setErrors(validate(sendvalue))
    //console.log(sendvalue)
    if (Object.keys(validate(sendvalue)).length === 0) {
      if (useForm.pId === '') {
        setOpen(true)
      } else {
        saveNContinue(sendvalue)
      }
    } else {
      //Code to call custom error message....
      addToast('Some required fields has not been answered.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  function saveNContinue(sendvalue) {
    // const sendvalue = useForm
    onSurveySave(sendvalue)
    setBtnLoading(true)
    setNextpage(true)
    /* const sendvalue = JSON.parse(JSON.stringify(useForm))

    if (sendvalue.startDate !== '') {
      sendvalue.startDate = moment(sendvalue.startDate, 'Do MMM, YYYY').format(
        'DD/MM/YYYY'
      )
    }
    if (sendvalue.endDate !== '') {
      sendvalue.endDate = moment(sendvalue.endDate, 'Do MMM, YYYY').format(
        'DD/MM/YYYY'
      )
    }

    //console.log(sendvalue)
    setErrors(validate(sendvalue))

    if (Object.keys(validate(sendvalue)).length === 0) {
      onSurveySave(sendvalue)
      setBtnLoading(true)
      setNextpage(true)
    } */
    //
  }
  function handleSelectChange(value, name, optionValue, isMulti) {
    if (name === 'subPopulation' && (value.id === 8 || value.id === 1)) {
      openSoftWarningPopup(true)
    }
    let options
    if (optionValue == 'key') {
      if (isMulti) {
        if (value && value.length) {
          options = value.map(item => item.key)
        } else {
          options = []
        }
      } else {
        options = value.key
      }
    } else {
      if (isMulti) {
        if (value && value.length) {
          options = value.map(item => item.id)
        } else {
          options = []
        }
      } else {
        options = value.id
      }
    }

    switch (name) {
      case 'population':
        setSubPopulationArr(value.children)

        return setStatform({
          ...useForm,
          subPopulation: 0,
          sampleValue: 0,
          subPopulationSpecify: '',
          [name]: options,
        })

        break
      case 'regionId':
        setClearRegion(false)
        setTimeout(function() {
          setClearRegion(true)
        }, 100)
        return setStatform({
          ...useForm,
          [name]: options,
          subRegion1Id: 0,
          subRegion2Id: 0,
        })

        break
      case 'subRegion1Id':
        setClearsubRegion2(false)
        setTimeout(function() {
          setClearsubRegion2(true)
        }, 100)
        return setStatform({ ...useForm, [name]: options, subRegion2Id: 0 })

        break

      case 'countryId':
        setClearRegion(false)
        setTimeout(function() {
          setClearRegion(true)
        }, 100)
        //console.log(options)
        if (options !== 197) {
          return setStatform({
            ...useForm,
            regionId: value.RegionDetails.key,
            subRegion1Id: value.SubRegion1Details.key,
            subRegion2Id: value.SubRegion2Details.key,
            [name]: options,
          })
        }
        return setStatform({
          ...useForm,
          regionId: 0,
          subRegion1Id: 0,
          subRegion2Id: 0,
          [name]: options,
        })
        break
      case 'ohiVersionId':
        onFetch(value['id'])
        setohiversionDefault(value['id'])
        setStatform({
          ...useForm,
          baseLanguage: 0,
          languages: '',
          ohiVersionId: value['id'],
        })
        break
      /* if (value.children.length === 0) {
            return setStatform({ ...useForm, subPopulation: 0 })
          } */

      default:
        break
    }
    setErrors({ ...errors, [name]: '' })
    return setStatform({ ...useForm, [name]: options })
  }

  function handleCheckboxChange(name) {
    return function(event) {
      setStatform({ ...useForm, [name]: event.target.checked })
    }
  }

  function handleInputChange(name) {
    return function(event) {
      // console.log(event.target, event.target.value)
      if (name === 'sampleValue') {
        if (event.target.value < 101) {
          setStatform({ ...useForm, [name]: event.target.value })
        } else {
          setStatform({ ...useForm, [name]: useForm.sampleValue })
        }
      } else {
        setStatform({ ...useForm, [name]: event.target.value })
      }
      setErrors({ ...errors, [name]: '' })
    }
  }
  function handleLaunchDateApply(event, picker) {
    setStatform({
      ...useForm,
      startDate: moment(picker.startDate).format(dateFormat),
    })
    setErrors({ ...errors, startDate: '' })
  }
  function handleEndDateApply(event, picker) {
    setStatform({
      ...useForm,
      endDate: moment(picker.startDate).format(dateFormat),
    })
    setErrors({ ...errors, endDate: '' })
  }

  function handleChipClick(name, value) {
    return function(event) {
      /* if (name === 'surveyType') {
        if (useForm.pId === '') {
          setStatform({ ...useForm, [name]: value })
          setErrors({ ...errors, [name]: '' })
        }
      } else {
        setStatform({ ...useForm, [name]: value })
        setErrors({ ...errors, [name]: '' })
      } */
      if (
        name === 'isMultipleCountries' &&
        value === 1 &&
        useForm.countryId === 197
      ) {
        setClearmainCountry(false)
        setTimeout(function() {
          setClearmainCountry(true)
        }, 100)
        setStatform({
          ...useForm,
          [name]: value,
          countryId: 0,
          regionId: 0,
          subRegion1Id: 0,
          subRegion2Id: 0,
        })
      } else {
        setStatform({ ...useForm, [name]: value })
      }

      setErrors({ ...errors, [name]: '' })
    }
  }
}

export default memo(SurveyForm)
